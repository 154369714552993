<template>
    <el-row>
<!--        <el-col>-->
<!--            <label class="color-primary">已选题目</label>-->
<!--        </el-col>-->
        <el-col class="delete-btn-group">
            <div>
                <label>共{{ pageInfo.total }}条</label>
                <el-select v-model="curPageSize" size="mini" @change="onSelectChange" style="margin-left: 20px">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    />
                </el-select>
            </div>

            <div>
                <el-button
                    type="danger"
                    size="mini"
                    @click="deleteSelected"
                    :disabled="loading || !isSelected"
                >删除选中</el-button>
<!--                <el-button type="danger" size="mini" @click="">按条件删除</el-button>-->
                <el-button
                    type="danger"
                    size="mini"
                    @click="deleteAll"
                    :disabled="loading || !pageInfo.total"
                    style="margin-left: 5px"
                >删除搜索结果</el-button>
            </div>
        </el-col>
        <el-col :lg="24" :md="24">
            <el-table
                    height="800px"
                    v-el-table-infinite-scroll="loadMoreQuestionData"
                    :data="questionList"
                    v-loading="loading"
                    @selection-change="getSelectList"
                    ref="elTable"
            >
                <el-table-column
                        type="selection"
                        width="55"
                        fixed="left"
                >
                </el-table-column>
                <el-table-column v-for="(item,index) in questionColumns"
                                 :key="index"
                                 :prop="item.value"
                                 :label="item.title"
                                 :width="item.width">
                </el-table-column>
                <slot name="last-column"></slot>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
    import elTableInfiniteScroll from 'el-table-infinite-scroll';

    export default {
        name: "selectedQuestionTable",
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        props: {
            id: {
                type: String,
                default: () => {
                    return ""
                }
            },
            pageInfo: {
                type: Object,
                default: () => {
                    return {
                        total: 0
                    }
                }
            },
            questionData: {
                type: Array,
                default: function () {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            isSelected: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                questionList: [],
                selectArticleList: [],
                totalCount: 0,
                loadingStatus: false,
                selectQuestionList: [],
                //题目数据
                questionColumns: [
                    {
                        title: '所属题库',
                        value: 'question_bank_name',
                    },
                    {
                        title: '业务类型',
                        value: 'business_type_name'
                    },
                    {
                        title: '题目',
                        value: 'topic_alias',
                        value_alias: 'topic'
                    },
                    {
                        title: '题型',
                        value: 'type_alias',
                        value_alias: 'type',
                    },
                    {
                        title: '分数',
                        value: 'score',
                    }
                ],
                cId: '',
                qtqId: '',
                scoreModelVisible: false,
                modifyScoreForm: {},
                options: [{
                    value: 10,
                    label: '10条/页'
                }, {
                    value: 30,
                    label: '30条/页'
                }, {
                    value: 50,
                    label: '50条/页'
                }, {
                    value: 100,
                    label: '100条/页'
                }],
                curPageSize: 30,

            }
        },
        mounted() {
            if (this.id) {
                this.cId = this.id;
            }

        },
        methods: {
            //加载
            loadMoreQuestionData() {
                this.$emit('load');
            },

            getSelectList(list) {
                let selectQuestionList = [];
                for (let item of list) {
                    let object = {
                        id: item.id
                    };
                    selectQuestionList.push(object);
                }
                this.$emit('select-list', selectQuestionList);
            },

            //修改分数
            editButton(row) {
                this.$emit('modify', row);
            },

            //选择
            onSelectChange(value) {
                this.$emit('change', value);
            },

            //删除选中
            deleteSelected() {
                this.$emit('deleteSelected');
            },
            // 删除所有
            deleteAll() {
                this.$emit('deleteAll');
            }

        },
        watch: {
            id: {
                handler(newVal) {
                    if (newVal) {
                        this.cId = newVal;
                    }
                }, immediate: true
            },

            questionData: {
                handler(newVal) {
                    if (newVal) {
                        this.questionList = newVal;
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .color-primary
        color #1890ff

    .delete-btn-group
        display flex
        align-items center
        justify-content space-between

    /deep/ .el-input
        width 100px
</style>