export default {
    data() {
        return {
            questionBankList: [],
            businessTypeList: [],
            typeList: [],
        }
    },
    methods: {
        //获取题库
        async initQuestionTypeList() {
            let {list} = await this.$api.getQuestionBankList();
            this.$nextTick(() => {
                let questionList = [];
                for (let key in list) {
                    let item = list[key];
                    let object = {
                        "label": item.name,
                        "value": item.id,
                    };
                    questionList.push(object);
                }
                this.questionBankList = questionList;
            });
        },
        //获取类型
        async resetBusinessTypeList() {
            let {list} = await this.$api.getBusinessTypeList();
            let businessList = [];
            for (let key in list.data) {
                let item = list.data[key];
                let object = {
                    "label": item.name,
                    "value": item.id,
                };
                businessList.push(object);
            }
            this.businessTypeList = businessList;
        },
        //获取题型
        async getQuestionTypeList() {
            let data = await this.$api.commonDict('question_category');
            this.typeList = data;
        },
    }
}